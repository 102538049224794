<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 表头 -->
    <div class="sec-title">优学卡订单列表</div>
    <!-- 查询 -->
    <div class="search">
      <el-form :inline="true" :model="form">
        <el-form-item label="订单编号">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input v-model="form.institutionsName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Onsubmint">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="flowing_no" label="订单编号"> </el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          label="用户名称"
        ></el-table-column>

        <el-table-column prop="map.userinfo.mobile" label="手机号">
        </el-table-column>
        <el-table-column prop="activity_id" label="活动id"> </el-table-column>
        <el-table-column prop="rcharge_abstract" label="订单类别">
        </el-table-column>
        <el-table-column prop="finished_time" label="支付时间">
        </el-table-column>
        <el-table-column prop="title" label="科目选择">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.map.selectMasterSetPriceEntitys"
              :key="index"
              >{{ item.title }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="finished" label="是否支付">
          <template slot-scope="scope">
            <span v-if="scope.row.finished">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_robot" label="是否机器人">
          <template slot-scope="scope">
            <span v-if="scope.row.is_robot">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination> -->
      <!-- 分页 -->
      <paging-fy
      @currentPageChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "活动管理中心",
        secondNav: "优学卡管理",
      },
      YNab: false,
      active: "",
      //分页
      pageSize: 10,
      currentPage: 1,
      total: 0,
      //查询
      form: { order: "", mobile: "", institutionsName: "" },
      //列表
      tableData: [],
    };
  },
  watch: {
    
  },
  methods: {
    GetList(data) {
      this.tableData = [];
      let aaa;
      aaa = {
        rcharge_type: "coupon_198",
        currentPage: this.currentPage,
        commodity_type: "A卡",
        // type:'体育宝',
        categories: "体育运动",
        source: "体育宝",
        ...data,
      };
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, { params: aaa })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Onsubmint() {
      var data = {};
      this.form.order ? (data.flowing_no = this.form.order) : "",
        this.form.mobile ? (data.login_name = this.form.mobile) : "",
        this.form.institutionsName
          ? (data.mechanism_name = this.form.institutionsName)
          : "",
        this.GetList(data);
    },
    handleSizeChange(val) {},

    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
  },
  mounted() {
    this.GetList();
  },
};
</script>
<style lang="less">
 .search{
  .el-form{
  display: flex;
  flex-wrap: wrap;
}
 }
</style>
